import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import {Attachments} from '../../components/Attachments'
import {TabView,TabPanel} from 'primereact/tabview'
import '../../components/Forms.css'


class NeedForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'need', '/Besoins', 'Needs',

      {
        need: {
          id: props.match.params.id,
          description: "",
          proposedResume: "",
          sentResume: "",
          metCandidate: "",
          recurrent: false,
          startDate: new Date (),
          state: "",
          state_id: 0,
          priority: "",
          priority_id: 0,
          assignedTo_id: 0,
          assignedTo: "",
          contact_id: (props.match.params.object ? parseInt (props.match.params.object, 10) : 0),
          contact_firstName: "",
          contact_lastName: "",
          contact_name: "",
          company_name: "",
        },

        mandatoryFields: {
          description: {test: FormContainer.checkNotEmpty, state: false},
          state: {test: FormContainer.checkNotEmpty, state: false},
          startDate: {test: FormContainer.checkNotEmpty, state: false},
        },

        assignedTo_list: [],
        state_list: [],
        priority_list: [],

      }
    )

    //  Local specifics handlers
    this.handleOpenContactTarget = this.handleOpenContactTarget.bind(this)
  }



  /*
   * Form data management
   */

  async getExternalValues () {

    // Retrieve ORM object informations from contact id
    //  commonName = human full name of the Item ("ex: Microsoft corporation")
    let objectInfos = { commonName: "" }
    if ( !this.state.need.contact_name && this.state.need.contact_id ) {
      objectInfos = await this.apiCall ( 'Needs', 'getObjectInfos', this.state.need.contact_id)
    }

    // Populate assignedTo dropdown selector options
    const assignedTo_list = await this.apiCall ( 'Users', 'list')
    assignedTo_list.values = assignedTo_list.values.map (u => {return {name: u.code + ' (' + u.firstName + ' ' + u.lastName + ')', id: u.id}})
    assignedTo_list.values.push ({id: 0, name: 'Personne (Aucun collaborateur)'})

    // Populate state dropdown selector options
    const state_list = await this.apiCall ('NeedStates', 'list')
    const defaultState = {id: 0, name: ''}
    const defaultStateValue = state_list.values.find (s => s.defaultValue === true) ;
    if ( defaultStateValue ) {
      defaultState.id = defaultStateValue.id
      defaultState.name = defaultStateValue.name
    }

    // Populate priority dropdown selector options
    const priority_list = await this.apiCall ('NeedPriorities', 'list')
    const defaultPriority = {id: 0, name: ''}
    const defaultPriorityValue = priority_list.values.find (p => p.defaultValue === true) ;
    if ( defaultPriorityValue ) {
      defaultPriority.id = defaultPriorityValue.id
      defaultPriority.name = defaultPriorityValue.name
    }

    // Setup values
    await this.setState (
      prevState => {
        prevState.need.state_id = prevState.need.state_id || defaultState.id
        prevState.need.state = prevState.need.state || defaultState.name
        prevState.need.priority_id = prevState.need.priority_id || defaultPriority.id
        prevState.need.priority = prevState.need.priority || defaultPriority.name
        prevState.need.contact_name = prevState.need.contact_name || objectInfos.commonName
        prevState.need.assignedTo_id = prevState.need.assignedTo_id || 0
        return {
          state_list: state_list.values,
          priority_list: priority_list.values,
          assignedTo_list: assignedTo_list.values,
          need: prevState.need,
        }
      }
    )
  }


  cleanUpValues (values) {
    // Dates are received encoded as an ISO 8601 string then converted to date object
    values.startDate = new Date (values.startDate)
  }


  /* Open the object in relation with this need */
  handleOpenContactTarget () {
    const route = this.getAppRoutes().find (r => r.service === 'Contacts')
    if ( ! route.access || route.access () ) {
      this.context.pushRoute (route.route + '/' + this.state.need.contact_id, this.getContext())
    } else {
      // TODO : Do a nice warning pannel !
      alert ("Accés refusé.")
    }
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  <div className="p-grid p-justify-between">
                    <div className="p-col-12 p-md-5">
                      Besoin client {this.state.need.contact_name}
                    </div>
                    <div className="p-col-12 p-md-5 form-text-align-md-right">
                      Entreprise : {this.state.need.company_name}
                    </div>
                  </div>
                </h1>
                <FormContainer
                  values={this.state.need}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <TabView>

                    <TabPanel header="Informations" rightIcon="pi pi-info">
                      <div className="form-new-line p-grid">


                        <div className="p-grid p-col-12 p-md-3">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="startDate" isMissing={this.state.mandatoryFields.startDate.state}>Date de début</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="startDate"
                              value={this.state.need.startDate}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-3">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="state" isMissing={this.state.mandatoryFields.state.state}>Etat</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="state"
                              options={this.state.state_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.need.state_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div >

                        <div className="p-grid p-col-12 p-md-3">
                          <div className="p-col-12">
                            <label htmlFor="priority">Priorité</label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="priority"
                              options={this.state.priority_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.need.priority_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div >

                        <div className="p-col-12 p-grid p-md-3">
                          <div className="p-col-12">
                          </div>
                          <div className="p-col-11 form-text-align-md-right">
                            <label htmlFor="recurrent">Besoin récurrent</label>
                          </div>
                          <div className="p-col-1">
                            <Checkbox
                              className="form-input"
                              inputId="recurrent"
                              disabled={!this.state.editMode}
                              value="recurrent"
                              onChange={this.handleCheckBox}
                              checked={this.state.need.recurrent}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-3">
                          <div className="p-col-12">
                            <label htmlFor="openIt" >Ouvrir le contact lié</label>
                          </div>
                          <div className="p-col-12">
                            <Button
                              id="openIt"
                              disabeld={this.props.locked}
                              className="p-button-info"
                              label={this.state.need.contact_name}
                              icon="pi pi-folder-open"
                              onClick={this.handleOpenContactTarget}
                              disabled={this.state.editMode}
                            />
                          </div >
                        </div >

                        <div className="p-grid p-col-12 p-md-3">
                          <div className="p-col-12">
                            <label htmlFor="assignedTo">Gestionnaire leader</label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="assignedTo"
                              options={this.state.assignedTo_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.need.assignedTo_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div>
                        </div >

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="description" isMissing={this.state.mandatoryFields.description.state}>Description</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="description"
                              disabled={!this.state.editMode}
                              value={this.state.need.description}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                        {/*
                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <label htmlFor="proposedResume">Candidats Proposés</label>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="proposedResume"
                              disabled={!(this.state.editMode && this.state.profile.globalManager)}
                              value={this.state.need.proposedResume}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>
                        */}

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <label htmlFor="sentResume">CVs positionnés</label>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="sentResume"
                              disabled={!this.state.editMode}
                              value={this.state.need.sentResume}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <label htmlFor="metCandidate">Présentations de Candidats</label>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="metCandidate"
                              disabled={!this.state.editMode}
                              value={this.state.need.metCandidate}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                      </div>
                    </TabPanel>

                    <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">
                      <div className="form-new-line p-grid">
                        <div className="p-col-12 form-new-line">
                          <label htmlFor="attachments">Pièces jointes associées :</label>
                        </div>
                        <div className="p-col-12">
                          <Attachments
                            id="attachments"
                            parentId={this.state.need.id}
                            locked={this.state.editMode}
                            getValues={this.getAttachmentsValues}
                            getVersions={this.getAttachmentVersions}
                            setVersions={this.setAttachmentVersions}
                            delete={this.deleteAttachment}
                            doUpload={this.fileUpload}
                            doDownload={this.fileDownload}
                            watch={this.state.profile}
                          >
                          </Attachments>
                        </div>
                      </div>
                    </TabPanel>

                  </TabView>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NeedForm

