import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import NeedPriorityList from './NeedPriorityList'
import NeedPriorityForm from './NeedPriorityForm'

export class NeedPriorities extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Priorites/Besoins' component={NeedPriorityList} />
        <Route path='/Priorites/Besoins/:id' component={NeedPriorityForm} />
      </Switch>
    )
  }
}
