import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {FilterService} from '../../services/FilterService'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class RequestTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'request', '/Demandes', 'Needs',
      {
        state_list: [],
        inCharge_list: [],
      }
    )

    this.setReferenceTeamFilter = FilterService.setReferenceTeamFilter.bind(this)
  }



  setCustomFilters (filters) {
    return this.setReferenceTeamFilter ()
  }

  /*
   * Get external values
   */
  async getExternalValues () {
    // Get requests states
    const state_list = await this.apiCall ('NeedStates', 'list')
    state_list.values = state_list.values.map (t => {return {label: t.name, value: t.id, ...t}})

    // Get users in charge list
    const inCharge_list = await this.apiCall ('Users', 'list')
    inCharge_list.values = inCharge_list.values.map (u => {return {label: u.code, value: u.id}})

    // Get needs priorities
    const priority_list = await this.apiCall ('NeedPriorities', 'list')
    priority_list.values = priority_list.values.map (t => {return {label: t.name, value: t.id, ...t}})

    this.setState (
      {
        state_list: state_list.values,
        priority_list: priority_list.values,
        inCharge_list: inCharge_list.values
      }
    )
  }


  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        noAutoFocus={this.props.noAutoFocus}
        onOpen={this.handleOpen}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column key="company_name" field="company_name" header="Entreprises" sortable={true} style={{width:'12%'}}/>
        <Column key="description" field="description" header="Description" sortable={true} style={{width:'30%'}} body={LazyDataTable.maxLinesTemplate(3)}/>
        <Column
          key="state"
          field="state"
          header="Etat"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.state_list}
          sortable={true}
          style={{width:'8%'}}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          key="priority"
          field="priority"
          header="Priorité"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.priority_list}
          sortable={true}
          style={{width:'15%'}}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          key="startDate"
          field="startDate"
          header="Début"
          filterMethod={LazyDataTable.dateFilter}
          sortable={true}
          style={{width:'1%'}}
          body={LazyDataTable.dateTemplate}
        />
        {/*
        <Column
          key="recurrent"
          field="recurrent"
          header=<i className="fas fa-history"></i>
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={LazyDataTable.booleanDefaultOptions}
          sortable={false}
          style={{textAlign:'center', width:'3%'}}
          body={LazyDataTable.booleanTemplate}
        />
        */}
        <Column
          key="inCharge"
          field="inCharge"
          header=<i className="pi pi-user"/>
          sortable={true}
          style={{width:'3%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.inCharge_list}
        />
        <Column
          key="assignedTo"
          field="assignedTo"
          header="Gestionnaire leader"
          sortable={true}
          style={{width:'4%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.inCharge_list}
        />
        {/*
        <Column
          key="proposedResume"
          //if={this.state.profile.expert}
          header="Candidats Proposés"
          field="proposedResume"
          style={{width:'1%'}}
          body={LazyDataTable.notesTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
        */}
        <Column
          key="sentResume"
          //if={this.state.profile.expert}
          header="CVs positionnés"
          field="sentResume"
          style={{width:'1%'}}
          body={LazyDataTable.notesTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
        <Column
          key="metCandidate"
          //if={this.state.profile.expert}
          header="Présentations de Candidats"
          field="metCandidate"
          style={{width:'1%'}}
          body={LazyDataTable.notesTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
      </LazyDataTable>
    )
  }
}

